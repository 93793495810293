import { useState, useEffect } from "react";
import "./App.css";
import { Piano } from "@tonejs/piano";
import { Level1, Level2, Level3, Level4 } from "./levels";
import {
  Beary,
  BearyIcon,
  Button,
  HealthBar,
  FlaskBar,
  Flask
} from "components";
import { random } from "utils";

const piano = new Piano({
  velocities: 5
});

piano.toDestination();
window.piano = piano;

const levels = [Level1, Level2, Level3, Level4];
const defaultTurnTime = 10;

let _time,
  _turnTime,
  _loading,
  interval,
  levelTimeInterval,
  animation,
  freezeTime,
  flaskTotal = 32,
  _level,
  _levelInd;

function App() {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [answer, setAnswer] = useState(null);
  const [selected, setSelected] = useState(null);
  const [correctTotal, setCorrectTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [health, setHealth] = useState(100);
  const [turnTime, setTurnTime] = useState(defaultTurnTime);
  const [time, setTime] = useState(300);
  const [pause, setPause] = useState(false);
  const [flasks, setFlasks] = useState(
    window.localStorage.getItem("flasks") || 0
  );
  const [levelInd, setLevelInd] = useState(
    window.localStorage.getItem("level") || 0
  );
  const [level, setLevel] = useState(new levels[levelInd]());

  _levelInd = levelInd;
  _level = level;
  _time = time;
  _turnTime = turnTime;
  _loading = loading;

  useEffect(() => {
    if (correctTotal === flaskTotal) {
      window.localStorage.setItem("flasks", flasks + 1);
      setFlasks(flasks + 1);
    }
  }, [correctTotal]);

  const reset = () => {
    setTime(300);
    setHealth(100);
    setCorrectTotal(0);
    setTurnTime(defaultTurnTime);
    clearInterval(interval);
    clearInterval(levelTimeInterval);
    start();
    setOptions([]);
  };

  const start = () => {
    levelTimeInterval = setInterval(() => {
      const newTime = _time - 1;
      if (newTime <= 0) {
        alert("Next level!");
        const newLevelInd = _levelInd + 1;
        setLevelInd(newLevelInd);
        setLevel(new levels[newLevelInd]());
        window.localStorage.setItem("level", newLevelInd);

        reset();
      } else {
        setTime(newTime);
      }
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener("options", e => {
      setOptions(e.detail);
    });

    window.addEventListener("answer", e => {
      setAnswer(e.detail);
    });
  }, []);

  useEffect(() => {
    if (health <= 0) {
      // if level ind 0 flask prompt -> advance
      if (levelInd === 0 && flasks.length) {
        alert(
          "Lose! Would you like to use a tonic to advance to the next level?"
        );
      } else if (levelInd === 0) {
        alert("Lose!");
      } else if (levelInd > 0 && level.timesLost === 2) {
        alert("Lose! You will go back a level if you lose again :(");
      } else if (levelInd > 0 && level.timesLost === 3) {
        alert("Lose! You must go back a level :(");
      } else {
        alert("Lose!");
      }

      reset();
    }
  }, [health]);

  const formatTime = (inputTime, t) => {
    const secs = inputTime % 60;
    if (t === "s") {
      return `${secs}`;
    } else {
      return `${Math.floor(inputTime / 60)}:${secs < 10 ? "0" + secs : secs}`;
    }
  };

  const play = () => {
    if (pause) {
      clearInterval(levelTimeInterval);
      clearInterval(interval);
      return;
    }
    if (animation) {
      animation.revert();
    }
    clearInterval(interval);
    setSelected(null);
    setLoading(true);
    setTotal(total + 1);

    piano.load().then(() => {
      setLoading(false);

      _level.play();

      _turnTime = defaultTurnTime;
      setTurnTime(_turnTime);
      interval = setInterval(() => {
        setTurnTime(_turnTime - 1);
        if (_turnTime - 1 === 0 && !_loading) {
          setHealth(health - 20);
          play();
        }
      }, 1000);

      animation = window.gsap.to(".beary", {
        y: "103vh",
        rotation: random(-90, 90),
        duration: defaultTurnTime
      });
    });
  };

  return (
    <div className="flex flex-col w-full h-full justify-center items-center relative p-5">
      <Beary />

      <div className="flex flex-col absolute top-2 left-2">
        {/*     <Button
          onClick={() => {
            setPause(true);
          }}
          disabled={pause}
        >
          {pause ? "Paused" : "Pause"}
        </Button> */}
      </div>

      <div className="flex flex-col absolute top-5 left-0 w-full justify-center items-center">
        <div className="flex flex-row w-full justify-center relative">
          <h1 className="text-2xl font-medium mb-2.5">Level {+levelInd + 1}</h1>
          <span className="absolute right-5 top-0 text-xl">
            {formatTime(time)}
          </span>
        </div>

        <HealthBar health={health} />

        <div className="flex flex-row mt-2.5 w-full justify-center items-center">
          <FlaskBar progress={(correctTotal / flaskTotal) * 100} />
        </div>

        <div className="flex flex-row w-full justify-center items-center mt-3">
          <div className="flex flex-row items-center">
            <BearyIcon className="mr-2.5" /> {correctTotal}
          </div>

          <div className="flex flex-row ml-10">
            <div className="mr-2.5">
              <Flask />
            </div>
            <span>{flasks}</span>
          </div>
        </div>
      </div>

      <div
        className="flex flex-col absolute items-center justify-center mt-5"
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "300px",
          zIndex: "10"
        }}
      >
        {options.length ? (
          <>
            {/* <span className="text-4xl font-medium py-10">
            {options.length ? formatTime(turnTime, "s") : null}
          </span> */}
            {options.map(opt => (
              <>
                <Button
                  key={opt}
                  className={`${
                    loading
                      ? "!border-slate-100 !text-slate-200"
                      : !selected
                      ? "!border-slate-200 !text-slate-500"
                      : opt === answer
                      ? "!border-green-400 !text-green-400"
                      : opt === selected && "!border-red-400 !text-red-400"
                  }`}
                  onClick={() => {
                    setSelected(opt);
                    if (opt === answer) {
                      setCorrectTotal(correctTotal + 1);
                    } else {
                      setHealth(health - 20);
                    }
                    setTimeout(() => {
                      play();
                    }, 2000);
                  }}
                >
                  {opt}
                </Button>
              </>
            ))}
          </>
        ) : (
          <Button
            disabled={loading}
            onClick={() => {
              start();
              play();
            }}
          >
            {loading ? "Loading..." : "Play"}
          </Button>
        )}
      </div>
    </div>
  );
}

export default App;
