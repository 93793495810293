export const Beary = props => {
  return (
    <div
      className={`beary w-10 h-10 absolute left-1/2 ${props.className || ""}`}
      style={{ top: "-20px", transform: "translateX(-50%) rotate(180deg)" }}
    >
      <img src="https://res.cloudinary.com/dm2ctfjv7/image/upload/v1664646519/bear_mddjhw.svg" />
    </div>
  );
};
