export const HealthBar = props => {
  return (
    <div
      className="flex flex-row bg-red-100 rounded-full"
      style={{ width: "100%", maxWidth: "300px", height: "15px" }}
    >
      <div
        className="flex flex-row bg-red-300 rounded-full"
        style={{ width: props.health + "%", height: "100%" }}
      ></div>
    </div>
  );
};
