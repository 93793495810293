import { random } from "utils";

const baseNotes = [
  "C4",
  "C#4",
  "D4",
  "Eb4",
  "E4",
  "F4",
  "F#4",
  "G4",
  "Ab4",
  "A4",
  "Bb4",
  "B4",
  "C5"
];

// key = half steps
const intervals = {
  0: "Unison",
  1: "Minor second",
  2: "Major second",
  3: "Minor third",
  4: "Major third",
  5: "Perfect fourth",
  6: "Tritone",
  7: "Perfect fifth",
  8: "Minor sixth",
  9: "Major sixth",
  10: "Minor seventh",
  11: "Major seventh",
  12: "Octave"
};

export class BaseLevel {
  constructor({ notes }) {
    this.notes = notes;
    this.intervals = intervals;
  }

  playNote = () => {
    const options = [];

    const ind = random(1, Object.keys(this.notes).length),
      note = this.notes[ind];

    window.piano.keyDown({
      note,
      time: "+1",
      velocity: 0.5
    });

    window.piano.keyUp({ note: note, time: "+3" });
    options.push(note);

    this.buildOptionsNotes(options);

    const event = new CustomEvent("answer", { detail: this.notes[ind] });
    window.dispatchEvent(event);
  };

  buildOptionsNotes = options => {
    const optSet = new Set(options);

    if (optSet.size === 5) {
      const event = new CustomEvent("options", {
        detail: [...optSet].sort((a, b) => 0.5 - Math.random())
      });
      window.dispatchEvent(event);
      return;
    }

    const ind = random(1, Object.keys(this.notes).length),
      note = this.notes[ind];

    if (!optSet.has(note)) {
      optSet.add(note);
    }

    this.buildOptionsNotes([...optSet]);
  };

  playInterval = (note1, note2, opts) => {
    let options = [];

    window.piano.keyDown({ note: note1, velocity: 0.5 });
    window.piano.keyUp({ note: note1, time: "+2" });
    window.piano.keyDown({ note: note2, time: "+1", velocity: 0.5 });
    window.piano.keyUp({ note: note2, time: "+3" });

    const halfSteps = Math.abs(
        baseNotes.indexOf(note1) - baseNotes.indexOf(note2)
      ),
      int = this.intervals[halfSteps];

    options.push(int);

    this.buildOptions(options, opts);

    const event = new CustomEvent("answer", {
      detail: int
    });
    window.dispatchEvent(event);
  };

  buildOptions = (options, opts = {}) => {
    const optSet = new Set(options);

    if (optSet.size === 5) {
      const event = new CustomEvent("options", {
        detail: [...optSet].sort((a, b) => 0.5 - Math.random())
      });
      window.dispatchEvent(event);
      return;
    }

    const ind = random(1, Object.keys(this.notes).length),
      note = this.notes[ind],
      ind2 = random(1, Object.keys(this.notes).length),
      note2 = this.notes[ind2],
      halfSteps = Math.abs(
        baseNotes.indexOf(note) - baseNotes.indexOf(opts.freeze || note2)
      ),
      int = this.intervals[halfSteps];

    if (!optSet.has(int)) {
      optSet.add(int);
    }

    this.buildOptions([...optSet], opts);
  };
}
