export const BearyIcon = props => {
  return (
    <div className={`w-5 h-5 ${props.className || ""}`}>
      <img
        className="h-full"
        src="https://res.cloudinary.com/dm2ctfjv7/image/upload/v1664646519/bear_mddjhw.svg"
      />
    </div>
  );
};
