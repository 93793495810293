import { random } from "utils";
import { BaseLevel } from "./BaseLevel";

const notes = {
  1: "C#4",
  2: "D4",
  3: "E4",
  4: "Eb4",
  5: "F4",
  6: "F#4",
  7: "G4",
  8: "A4",
  9: "B4",
  10: "C5"
};

export class Level2 extends BaseLevel {
  constructor() {
    super({ notes });
    this.notes = notes;
    this.timesLost = window.localStorage.getItem("level2_timesLost") || 0;
  }

  setTimesLost = () => {
    window.localStorage.setItem("level2_timesLost", this.timesLost + 1);
    this.timesLost = this.timesLost + 1;
  };

  play() {
    const prob = this.probability();

    if (prob === "interval") {
      const ind = random(1, Object.keys(notes).length),
        note = this.notes[ind];

      this.playInterval(note, "C4", { freeze: "C4" });
    } else {
      this.playNote();
    }
  }

  probability() {
    const ind = random(1, 100);
    if (ind > 70) {
      return "note";
    }

    return "interval";
  }
}
