import { random } from "utils";
import { BaseLevel } from "./BaseLevel";

const notes = {
  1: "C#4",
  2: "D4",
  3: "E4",
  4: "Eb4",
  5: "F4",
  6: "F#4",
  7: "G4",
  8: "A4",
  9: "B4",
  10: "C5"
};

export class Level3 extends BaseLevel {
  constructor() {
    super({ notes });
    this.notes = notes;
    this.timesLost = window.localStorage.getItem("level3_timesLost") || 0;
  }

  setTimesLost = () => {
    window.localStorage.setItem("level3_timesLost", this.timesLost + 1);
    this.timesLost = this.timesLost + 1;
  };

  play() {
    const asc = this.dirProbability();

    if (asc) {
      const isNote = this.noteProbability();

      if (!isNote) {
        const ind = random(1, Object.keys(notes).length),
          note = this.notes[ind];

        this.playInterval("C4", note, { freeze: "C4" });
      } else {
        this.playNote();
      }
    } else {
      const isNote = this.noteProbability();

      if (!isNote) {
        const ind = random(1, Object.keys(notes).length),
          note = this.notes[ind];

        this.playInterval(note, "C4", { freeze: "C4" });
      } else {
        this.playNote();
      }
    }
  }

  noteProbability() {
    const ind = random(1, 100);
    return ind > 80;
  }

  dirProbability() {
    const ind = random(1, 100);
    return ind > 50;
  }
}
