import React, { useEffect, useState } from "react";
import { random } from "utils";
/* background: #e7edf5;
color: #8c9095; */
const Button = props => {
  const [borderTopLeftRadius, setBorderTopLeftRadius] = useState();
  const [borderTopRightRadius, setBorderTopRightRadius] = useState();
  const [borderBottomLeftRadius, setBorderBottomLeftRadius] = useState();
  const [borderBottomRightRadius, setBorderBottomRightRadius] = useState();

  useEffect(() => {
    setBorderTopLeftRadius(random(38, 100));
    setBorderTopRightRadius(random(38, 100));
    setBorderBottomLeftRadius(random(38, 100));
    setBorderBottomRightRadius(random(38, 100));
  }, []);

  return (
    <button
      className={`bg-white border border-slate-200 text-slate-500 py-2.5 px-5 my-2.5 ${
        props.loading ? "border-slate-100" : "border-slate-200"
      } ${props.className || ""}`}
      style={{
        // color: "rgb(98 101 104)",
        width: "max-content",
        borderTopLeftRadius,
        borderTopRightRadius,
        borderBottomLeftRadius,
        borderBottomRightRadius
      }}
      onClick={props.onClick}
      disabled={props.loading}
    >
      {props.children}
    </button>
  );
};

export default React.memo(Button);
